import { Injectable } from '@angular/core';
import { ToastButton, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastCtrl: ToastController,
  ) { }

  async show(text: string, time: number = 2000, buttons?: (ToastButton | string)[]): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: time,
      buttons: buttons,
      position: 'bottom'
    });
    return toast.present();
  }
}
