import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { AppConfiguration } from '../const/config';
import { environment } from 'src/environments/environment';
import { Http } from '@capacitor-community/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private angularhttp: HttpClient,
    private storageService: StorageService,
  ) { }

  public post(url: string, body: object): Promise<any> {
    let mainheaders = {};
    if (AppConfiguration.API_URL != url) {
      mainheaders = {
        'Authorization': `Basic ${this.storageService.getAuthenticationHash()}`,
      };
    }
    const httpOptions = {
      headers: mainheaders
    };
    console.log('POST request', url, body);
    if (environment.native) {
      const options = {
        url: url,
        headers: mainheaders,
        data: body
      };
      return new Promise((resolve, reject) => {
        Http.post(options).then(response => {
          console.log("POST request capacitor response", response);
          if (response.status && response.status >= 400) {
            reject(response.data);
          }
          resolve(response.data);
        }).catch(err => {
          console.log("POST request capacitor error", err);
          reject(err);
        });
      });
    }
    return this.angularhttp.post(url, JSON.stringify(body), httpOptions).toPromise();
  }

  public get(url: string, data?: any): Promise<any> {
    if (data) {
      url += '?';
      for (let [key, value] of Object.entries(data)) {
        url = url + key + '=' + value + '&';
      }
      if (url.substr(url.length - 1) == '&') {
        url = url.slice(0, -1);
      }
    }
    const headers = { 'Authorization': `Basic ${this.storageService.getAuthenticationHash()}` };
    const httpOptions = { headers: headers };
    console.log('GET request', url, data);
    if (environment.native) {
      const options = {
        url: url,
        headers: headers,
        params: data
      };
      return new Promise((resolve, reject) => {
        Http.get(options).then(response => resolve(response.data)).catch(err => reject(err));
      });
    }
    return this.angularhttp.get(url, httpOptions).toPromise();
  }

  public put(url: string, body: object): Promise<any> {
    const headers = { 'Authorization': `Basic ${this.storageService.getAuthenticationHash()}` };
    const httpOptions = { headers: headers };
    console.log('PUT request', url, body);
    if (environment.native) {
      const options = {
        url: url,
        headers: headers,
        data: body
      };
      return new Promise((resolve, reject) => {
        Http.put(options).then(response => resolve(response.data)).catch(err => reject(err));
      });
    }
    return this.angularhttp.put(url, JSON.stringify(body), httpOptions).toPromise();
  }

  public delete(url: string): Promise<any> {
    const headers = { 'Authorization': `Basic ${this.storageService.getAuthenticationHash()}` };
    const httpOptions = { headers: headers };
    console.log('DELETE request', url);
    if (environment.native) {
      const options = {
        url: url,
        headers: headers,
        method: "DELETE"
      };
      return new Promise((resolve, reject) => {
        Http.request(options).then(response => resolve(response.data)).catch(err => reject(err));
      });
    }
    return this.angularhttp.delete(url, httpOptions).toPromise();
  }
}
