import { Injectable } from '@angular/core';
import { PaymentMethod } from '../types/PaymentMethod';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor() { }

  public convertApiPaymentMethod(paymentMethod: any): PaymentMethod {
    return {
      paymentMethodId: +paymentMethod.id_payment_method,
      flags: +paymentMethod.flags,
      type: +paymentMethod.type,
      name: paymentMethod.name,
    }
  }
}
