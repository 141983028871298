import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { PageUrl } from '../enum/PageUrl';
import { AppPage } from '../types/AppPage';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(
    private router: Router,
  ) { }

  goToPage(pageUrl: PageUrl): Promise<boolean> {
    return this.router.navigateByUrl(pageUrl);
  }

  goToPageWithId(pageUrl: PageUrl, id: number): Promise<boolean> {
    return this.router.navigateByUrl(pageUrl + "/" + id.toString());
  }
}
