import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageUrl } from './enum/PageUrl';
import { LoginService } from './services/login.service';
import { MenuService } from './services/menu.service';
import { PernamentStorageService } from './services/pernament-storage.service';
import { RouterService } from './services/router.service';
import { StorageService } from './services/storage.service';
import { Company } from './types/Company';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public pagesWithoutMenu = ['/login'];

  constructor(
    private router: Router,
    public menuService: MenuService,
    private pernamentStorageService: PernamentStorageService,
    public storageService: StorageService,
    private loginService: LoginService,
    private routerService: RouterService,
  ) {
    this.pernamentStorageService.init().then(() => {
      this.tryLogin();
    });
  }

  public canHideMenu(): boolean {
    return this.pagesWithoutMenu.includes(this.router['routerState'].snapshot.url);
  }

  private tryLogin(): void {
    this.loginService.trySavedLogin().then(() => {
      if (this.router['routerState'].snapshot.url as PageUrl === PageUrl.LOGIN) {
        this.routerService.goToPage(PageUrl.HOME);
      }
    }).catch(() => this.routerService.goToPage(PageUrl.LOGIN));
  }

  public getDisplayCompanyName(): string {
    for (let i = 0; i < this.storageService.getCompanies().length; i++) {
      const company: Company = this.storageService.getCompanies()[i];
      if (company.companyId === this.storageService.getSelectedCompany()) {
        return company.name;
      }
    }
    return '';
  }

  public async changedCompany(event): Promise<void> {
    let companyId: number = +event.target.value;
    this.storageService.setSelectedCompany(companyId);
    this.loginService.setupApiKey();
    await this.loginService.saveLoginData();
    await this.loginService.trySavedLogin();
    this.routerService.goToPage(PageUrl.HOME);
  }
}
