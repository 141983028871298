import { Injectable } from '@angular/core';
import { PageUrl } from '../enum/PageUrl';
import { AppPage } from '../types/AppPage';
import { PernamentStorageService } from './pernament-storage.service';
import { RouterService } from './router.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public readonly pages: Array<AppPage> = [
    {
      url: '/home',
      title: 'Domů',
      icon: 'bulb'
    },
    {
      url: '/create/0',
      title: 'Vystavit doklad',
      icon: 'document-text'
    },
    {
      url: '/invoices',
      title: 'Doklady',
      icon: 'documents'
    },
    {
      url: '/directory',
      title: 'Zákazníci',
      icon: 'people'
    },
  ];

  constructor(
    private pernamentStorageService: PernamentStorageService,
    private routerService: RouterService,
    private toastService: ToastService,
  ) { }

  public async logout(): Promise<void> {
    await this.pernamentStorageService.clear();
    await this.routerService.goToPage(PageUrl.LOGIN);
    this.toastService.show("Odhlášení úspěšné.");
  }
}
