import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private datePipe: DatePipe,
  ) { }

  public getTodayDate(): string {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }
}
