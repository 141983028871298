import { Injectable } from '@angular/core';
import { StorageKey } from '../enum/StorageKey';
import { Company } from '../types/Company';
import { AlertService } from './alert.service';
import { EndpointService } from './endpoint.service';
import { PernamentStorageService } from './pernament-storage.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private endPointService: EndpointService,
    private storageService: StorageService,
    private pernamentStorageService: PernamentStorageService,
    private alertService: AlertService,
  ) { }

  public loginWithCredentials(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.endPointService.login(email, password).then(async data => {
        this.storageService.setApiCompanies(data.companies);
        this.storageService.setSelectedCompany(+data.id_company_last_used);
        this.storageService.setUserEmail(email);
        this.setupApiKey();
        await this.loadSettings();
        await this.saveLoginData();
        resolve();
      }).catch(err => {
        console.log(err)
        this.alertService.show("Chyba!", "Přihlášení se nezdařilo, zkontrolujte přihlašovací údaje.");
        reject(err)
      });
    });
  }

  public setupApiKey(): void {
    let lastCompany: Company = this.storageService.getCompanies().find(c => c.companyId === this.storageService.getSelectedCompany());
    this.storageService.setAuthenticationHash(
      btoa(`${this.storageService.getUserEmail()}:${lastCompany.apiKey}`)
    );
  }

  private loadSettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.endPointService.testApiHash().then(data => {
        this.storageService.setApiNumberSeries(data.settings.numberSeries);
        this.storageService.setApiPaymentMethods(data.settings.paymentMethods);
        this.storageService.setApiCompanies(data.settings.companies);
        resolve();
      }).catch(err => reject(err));
    });
  }

  public saveLoginData(): Promise<void> {
    return this.pernamentStorageService.set(StorageKey.LOGIN_DATA, {
      authenticationHash: this.storageService.getAuthenticationHash(),
      userEmail: this.storageService.getUserEmail(),
      selectedCompany: this.storageService.getSelectedCompany()
    });
  }

  public trySavedLogin(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.pernamentStorageService.get(StorageKey.LOGIN_DATA).then(async storageData => {
        if (storageData !== null) {
          this.storageService.setAuthenticationHash(storageData.authenticationHash);
          this.storageService.setUserEmail(storageData.userEmail);
          await this.loadSettings().then(() => resolve()).catch(() => reject());
          this.storageService.setSelectedCompany(storageData.selectedCompany);
        } else {
          reject();
        }
      });
    });
  }
}
