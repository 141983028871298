import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StorageKey } from '../enum/StorageKey';

@Injectable({
  providedIn: 'root'
})
export class PernamentStorageService {

  constructor(
    private ionicStorage: Storage,
  ) { }

  public set(key: StorageKey, value: any): Promise<any> {
    return this.ionicStorage.set(key, value);
  }

  public get(key: StorageKey): Promise<any> {
    return this.ionicStorage.get(key);
  }

  public clear(): Promise<void> {
    return this.ionicStorage.clear();
  }

  public init(): Promise<Storage> {
    return this.ionicStorage.create();
  }
}
