import { Injectable } from '@angular/core';
import { AppConfiguration } from '../const/config';
import { Contact } from '../types/Contact';
import { Invoice } from '../types/Invoice';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor(
    private httpService: HttpService,
  ) { }

  public login(email: string, password: string): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/login/", { email: email, password: password });
  }

  public testApiHash(): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/mobile-autologin/", { version: '1.1.0' });
  }

  public createInvoice(invoice: any): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/invoice/", invoice);
  }

  public updateInvoice(invoice: any): Promise<any> {
    return this.httpService.put(AppConfiguration.API_URL + "/invoice/" + invoice.id.toString(), invoice);
  }

  public getInvoice(invoiceId: number): Promise<any> {
    return this.httpService.get(AppConfiguration.API_URL + "/invoice/" + invoiceId.toString());
  }

  public getInvoices(filter: object): Promise<any> {
    return this.httpService.get(AppConfiguration.API_URL + "/invoice/", filter);
  }

  public createContact(contact: any): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/contact/", contact);
  }

  public updateContact(invoice: any): Promise<any> {
    return this.httpService.put(AppConfiguration.API_URL + "/contact/" + invoice.id.toString(), invoice);
  }

  public getContact(contactId: number): Promise<any> {
    return this.httpService.get(AppConfiguration.API_URL + "/contact/" + contactId.toString());
  }

  public getContacts(filter: any): Promise<any> {
    return this.httpService.get(AppConfiguration.API_URL + "/contact/", filter);
  }

  public stornoInvoice(invoice: Invoice, storno = true): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/invoice/" + invoice.invoiceId.toString() + "/do/" + ((storno) ? "storno" : "undo-storno") + "/", {});
  }

  public archiveInvoice(invoice: Invoice, archive = true): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/invoice/" + invoice.invoiceId.toString() + "/do/" + ((archive) ? "archive" : "undo-archive") + "/", {});
  }

  public payInvoice(invoice: Invoice, date: string): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/invoice/" + invoice.invoiceId.toString() + '/do/pay/', {});
  }

  public sendInvoiceToEet(invoice: Invoice): Promise<any> {
    return this.httpService.post(AppConfiguration.API_URL + "/invoice/" + invoice.invoiceId.toString() + '/do/send-eet/', {});
  }

  public deleteInvoice(invoice: Invoice): Promise<any> {
    return this.httpService.delete(AppConfiguration.API_URL + "/invoice/" + invoice.invoiceId.toString());
  }

  public deleteContact(contact: Contact): Promise<any> {
    return this.httpService.delete(AppConfiguration.API_URL + "/contact/" + contact.contactId.toString());
  }
}
