import { Injectable } from '@angular/core';
import { Company } from '../types/Company';
import { Invoice } from '../types/Invoice';
import { NumberSerie } from '../types/NumberSerie';
import { PaymentMethod } from '../types/PaymentMethod';
import { NumberSerieService } from './number-serie.service';
import { PaymentMethodService } from './payment-method.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private authenticationHash: string;
  private userEmail: string;

  private selectedCompany: number = 0;

  private companies: Array<Company> = [];
  private numberSeries: Array<NumberSerie> = [];
  private paymentMethods: Array<PaymentMethod> = [];
  private defaultInvoiceData: Invoice;
  private isCompanyVatPayer = true;

  constructor(
    private numberSerieService: NumberSerieService,
    private paymentMethodService: PaymentMethodService,
  ) { }

  public getAuthenticationHash(): string {
    return this.authenticationHash;
  }

  public setAuthenticationHash(authenticationHash: string): void {
    this.authenticationHash = authenticationHash;
  }

  public setSelectedCompany(selectedCompany: number): void {
    let company: Company = this.companies.find(c => c.companyId === selectedCompany);
    this.isCompanyVatPayer = company.flags === 1;
    this.selectedCompany = selectedCompany;
  }

  public getSelectedCompany(): number {
    return this.selectedCompany;
  }

  public isVatPayer(): boolean {
    return this.isCompanyVatPayer;
  }

  public getDefaultInvoiceData(): Invoice {
    return this.defaultInvoiceData;
  }

  public setApiCompanies(apiCompanies: Array<any>): void {
    this.companies = [];
    apiCompanies.forEach(c => {
      this.companies.push({
        companyId: +c.id_company,
        flags: +c.flags,
        name: c.name,
        apiKey: c.api_key
      });
    });
  }

  public setApiNumberSeries(apiNumberSeries: Array<any>): void {
    this.numberSeries = [];
    apiNumberSeries.forEach(item => this.numberSeries.push(this.numberSerieService.convertApiNumberSerie(item)));
  }

  public setApiPaymentMethods(apiPaymentMethods: Array<any>): void {
    this.paymentMethods = [];
    apiPaymentMethods.forEach(item => this.paymentMethods.push(this.paymentMethodService.convertApiPaymentMethod(item)));
  }

  public getCompanies(): Array<Company> {
    return this.companies;
  }

  public setUserEmail(userEmail: string): void {
    this.userEmail = userEmail;
  }

  public getUserEmail(): string {
    return this.userEmail;
  }

  public getPaymentMethods(): Array<PaymentMethod> {
    return this.paymentMethods;
  }

  public getNumberSeries(): Array<NumberSerie> {
    return this.numberSeries;
  }
}
