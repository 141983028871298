import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { DateService } from './date.service';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController,
    private dateService: DateService,
  ) { }

  async show(title: string, text: string, popupbuttons: Array<any> = ['OK']) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: text,
      buttons: popupbuttons
    });
    await alert.present();
  }

  async showDate(title: string, text: string, successHandler: any, primaryButtonText = 'Ok', cancelButtonText = 'Zrušit', inputName = 'input'): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: title,
      message: text,
      inputs: [{
        name: inputName,
        type: 'date',
        value: this.dateService.getTodayDate(),
        min: '2010-01-01',
        max: '2030-01-01',
      }],
      buttons: [{
        text: cancelButtonText,
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: primaryButtonText,
        handler: successHandler
      }],
    });
  }
}
