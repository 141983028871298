import { Injectable } from '@angular/core';
import { NumberSerie } from '../types/NumberSerie';

@Injectable({
  providedIn: 'root'
})
export class NumberSerieService {

  constructor() { }

  public convertApiNumberSerie(numberSerie: any): NumberSerie {
    return {
      numberSerieId: +numberSerie.id_number_series,
      type: +numberSerie.type,
      name: numberSerie.name,
      pattern: numberSerie.pattern,
    }
  }
}
